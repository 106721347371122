import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesRouge: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Quency: Escape Queen guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_quency_escape.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Quency: Escape Queen Guide & Review</h1>
          <h2>
            A guide & review for Quency: Escape Queen in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>05/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Quency: Escape Queen " />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="quency-escape-queen"
                  enablePopover
                />
              </div>
              <p>
                The charm of a fleeting life is the adrenaline-pumping dangers
                within. That is probably what the legendary escape artist Quency
                would say when she fled ShiftUp's confinement to broadcast what
                SMGs are truly capable of. Quency: Escape Queen is a damage
                dealer who can deal distributed damage and continuous damage
                through basic attacks. Wielding dual SMGs, she can speedily
                vaporize enemies and accumulate stacks, unlocking a cache of
                practical buffs alongside ATK & Hit Rate. Her Burst Skill allows
                her to strengthen herself further and perform a screenwipe,
                annihilating enemies outside range.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Quency: Escape Queen (abbreviated hereafter as QuencyEQ) is a
                competitive damage dealer from the Water camp that can vie with
                fabled damage dealers from the garrison. She is not Alice-level
                broken, but she should be able to healthily compete with the
                likes of XLud and Modernia in terms of damage, but there is
                bound to be{' '}
                <strong>
                  some variability depending on the stage design & features.
                </strong>{' '}
                Against single targets in close range, the 30% damage distance
                bonus will take her DPS up a notch, but this is rarely the case
                in most content. She suffers from inconsistency at long ranges
                even with Hit Rate buff as she needs to hit shots to stack
                buffs. Missing shots can be detrimental. She has a screenwipe to
                help manage unreachable targets, however. Overall, her DPS in a
                conducive environment seems very promising, at least
                mathematically.
              </p>
              <p>
                On the topic of synergy, at the time of writing, QuencyEQ
                synergizes well with most support units. ATK buffs are diluted
                because she has many but still effective. Stack refreshers are
                not urgent because the impact is minimal (since stacks last too
                short for timings to align). Honorable mention to SMary for
                being the only unit providing Elemental DMG buff.
              </p>
              <p>
                As regards investments, she is somewhat investment-heavy as{' '}
                <strong>her S2 should be maxed as soon as possible</strong> to
                minimize inconsistency (missing shots; missing core shots). It
                also stores all of her ATK buffs, which are crucial to providing
                damage and counterbalancing stat penalty. She also needs a
                highly leveled Cube (at least Level 7 of Resilience/Bastion) and{' '}
                <strong>a properly grounded OL</strong>, consisting of{' '}
                <strong>1~2× Hit Rate & 1~2× Max Ammo</strong>. Depending on the
                theme of the comp she is in, this ruleset may change.
              </p>
              <p>
                When it comes to usage, she will definitely be used in
                <strong>Water-Weak Raids</strong> and can be used in Campaign,
                PvP, and Missilis Tower. Her solo performance is incredible, but
                the lack of special connection with any support is the problem.
                Couples such as Ein + SAnis make a great combo together, and the
                Asuka + Rei + Modernia triad will always be deadly. Meanwhile,
                QuencyEQ does not have an exclusive support yet, unless Miranda
                counts. Even then, she is still{' '}
                <strong>
                  one of the best units against Harvester in Anomaly
                  Interception
                </strong>
                , making her worth investing in if you need T9M gear.
              </p>
              <p>
                Oh, and one more thing, she is MANUAL-HEAVY and absolutely not
                AUTO-FRIENDLY. This is because somehow the game codes it so
                that, if AUTO-AIM/FIRE is turned on, everyone suddenly loses
                their eyesight and becomes more inaccurate.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                Her masterful manipulation ability has beguiled us into pulling
                her, so you should too. In all honesty, she is worth pulling,
                but anniversary is around the corner, so you will be the one
                making decision this time, not us. Quency being Water is a
                dependable sidekick for clearing content that is Water-weak
                since we do not have a lot of good Water units at the moment.
                That includes current and future Raids as well as Harvester AI.
                She also holds some authority in Campaign, PvP, and Missilis
                Tower, though not as much as the other recently released units.
              </p>
              <p>
                Now, Anniversary Units are likely to be more broken, so you
                should always prioritize them first if you lack funds to get
                both. However, this also comes with a risk of you not having
                access to QuencyEQ for long and missing any potential synergy
                with future Nikke(s).
              </p>
              <p>
                We do not really recommend using Golden Mileage Ticket(s) nor
                obtaining Dupe(s) for units who can be acquired through Ordinary
                Recruit (except if it is overbearing like Alice), but that is up
                to you. If you are a competitive Solo Raid player and are
                concerned about being handicapped in future raids, consider
                getting one copy.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                QuencyEQ is a dual-SMG B3 who fires 40 bullets every second,
                dealing 202.4% of final ATK as damage and consuming 20 ammo in
                total. Her basic attack ratio is not high but is considerably
                higher than the average AR and should be equal to most other
                SMGs. It has a higher core multiplier of 2.5× as opposed to the
                usual 2× on most non-SMG units. Basic attacks constitute 76% of
                her total damage (68% if without Core), and therefore she is
                heavily reliant on hitting basic attacks. It is also possible to
                run her as an off-burst unit.
              </p>
              <p>
                She is also MANUAL-HEAVY and not AUTO-FRIENDLY. Turning off
                AUTO-AIM/FIRE will actually increase the accuracy of every unit
                in your team (except MG & RL). While this has been present for
                some time, it is not widely known, and it might just be the
                cause of you not hitting Core Shots with RH & Alice. With the
                release of this unit, we would like to remind you that this
                mechanic (or rather limitation) exists.{' '}
              </p>
              <h5>Is the Route Secure?</h5>
              <blockquote>
                <p>
                  ■ Activates only when Explore Route Stage 1 is fully stacked.
                  Affects self.
                </p>
                <p>Distributed Damage ▲ 49.58% continuously.</p>
                <p>
                  ■ Activates only when Explore Route Stage 2 is fully stacked.
                  Affects self.
                </p>
                <p>Damage dealt when attacking core ▲ 25.25% continuously.</p>
                <p>
                  ■ Activates only when Explore Route Stage 3 is fully stacked.
                  Affects self.
                </p>
                <p>Critical Rate ▲ 16.73% continuously.</p>
              </blockquote>
              <p>
                This skill becomes more powerful as she enters more advanced
                stages with her Skill 2, which can be done via landing basic
                attacks and accumulating stacks.
              </p>
              <ul>
                <li>
                  Upon reaching{' '}
                  <strong>
                    maximum stacks of Stage 1, she gains 49.58% Distributed
                    Damage buff
                  </strong>
                  , which effectively increases her Burst Skill damage by the
                  same value. Always achieve this milestone first before using
                  Burst Skill. It is easy to maintain and can be considered
                  permanent if you keep on shooting.
                </li>
                <li>
                  Upon reaching{' '}
                  <strong>
                    maximum stacks of Stage 2, she gains an extra 0.25× Core
                    Damage Multiplier.
                  </strong>{' '}
                  This does not make her deal 25% more damage against cores;
                  rather, it increases her Core Damage Multiplier from 2.5× to
                  2.75×, which is diluted by Damage Distance, Full Burst, and
                  CRIT Multipliers.
                </li>
                <li>
                  Upon reaching{' '}
                  <strong>
                    maximum stacks of Stage 3, she gains 16.73% Critical Rate
                  </strong>
                  . This is somewhat contradictory to Core Damage Multiplier
                  because CRIT belongs in the same category as them and is
                  diluted. However, against bosses without core, the impact is
                  substantial. It also increases her overall Burst Skill damage
                  (which can CRIT but cannot core-hit).
                </li>
              </ul>
              <h5>Exploring the Route Together</h5>
              <blockquote>
                <p>
                  ■ Activates after landing 2 normal attack(s). Effects self.
                  Effects in each phase vary. Previous effects trigger
                  repeatedly.
                </p>
                <p>■ Stage 1: Affects self.</p>
                <p>
                  Hit Rate ▲ 1.36%, stacks up to 10 time(s) and lasts for 2 sec.
                </p>
                <p>ATK ▲ 2.45%, stacks up to 10 time(s) and lasts for 2 sec.</p>
                <p>
                  ■ Stage 2: Activates when Explore Route Stage 1 is fully
                  stacked. Affects self.
                </p>
                <p>
                  Hit Rate ▲ 2.71%, stacks up to 10 time(s) and lasts for 1 sec.
                </p>
                <p>ATK ▲ 4.9%, stacks up to 10 time(s) and lasts for 1 sec.</p>
                <p>
                  ■ Stage 3: Activates when Explore Route Stage 2 is fully
                  stocked. Affects self.
                </p>
                <p>
                  Hit Rate ▲ 4.08%, stacks up to 5 time(s) and lasts for 0.5
                  sec.
                </p>
                <p>
                  ATK ▲ 7.36%, stacks up to 5 time(s) and lasts for 0.5 sec.
                </p>
              </blockquote>
              <p>
                The activator of Skill 1 and most susceptible to how much Reload
                Speed buff QuencyEQ possesses. Basically, every time QuencyEQ
                expends <strong>2 ammo or launches 4 basic attack</strong>{' '}
                instances, she gains one stack of Stage 1, Stage 2, and Stage 3
                buffs. Each Stage offers a different buff intensity, up to a
                grand total of <strong>61.1% Hit Rate + 110.3% ATK</strong>{' '}
                combined. Higher stage buffs can only be instated and stacked if
                the previous Stage buff has already been maxed.
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/quency_guide_2.webp"
                alt="Kit"
              />
              <ul>
                <li>
                  <strong>Stage 1:</strong> Max stacks (10) grant a total of{' '}
                  <strong>13.6% Hit Rate and 24.5% ATK</strong>. Stacks last 2
                  seconds. They are easy to maintain and can be considered
                  permanent if QuencyEQ can keep shooting at a valid target,
                  even without any Cube and outside Burst Skill buffs.
                </li>
                <li>
                  <strong>Stage 2:</strong> Max stacks (10) grant a total of{' '}
                  <strong>27.1% Hit Rate and 49% ATK</strong>. Stacks last 1
                  second. These are impossible to maintain without &gt;47%
                  Reload Speed buff (validated for 50.43%+). Crown + Resilience
                  should be plenty and leave ample room for error.
                </li>
                <li>
                  <strong>Stage 3:</strong> Max stacks (5) grant a total of{' '}
                  <strong>20.4% Hit Rate and 36.8% ATK</strong>. Stacks last 0.5
                  seconds. These are impossible to maintain without &gt;97%
                  Reload Speed buff (validated for 97.56%+). You can achieve
                  97.56% Reload Speed with Crown S2 Level 10, Resilience Level
                  7+, and Quency Burst Level 8.
                </li>
              </ul>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Reload Speed
                  Formula
                </h6>
                <p>
                  At 60 FPS, most units take{' '}
                  <strong>11 frames × 2 + Reload Time</strong> to reload their
                  weapon and fire their next shot. This is very consistent
                  across multiple units and measured by examining the frametime
                  of the last ammo shot before reload and the frametime of the
                  first ammo shot after reload. Additionally, if &gt;109% Reload
                  Speed threshold is reached, 11 frames will be deducted from
                  this length.
                </p>
              </blockquote>
              <p>
                To optimize the effect longevity of this skill, one must reload
                less or prevent the expiration of stacks. To reload less, one
                needs sufficient Max Ammo to last until a breakpoint in time and
                amount is reached. To prevent the expiration of stacks, one
                needs Resilience + Reload Speed buffers in team. Besides that,
                this amount of Hit Rate provided by this skill alone is still
                not enough to achieve line-shot or comfortable shooting accuracy
                (even with Auto-Aim/Fire turned off). We will discuss potential
                solutions in detail in Gear Investments section.
              </p>
              <Alert variant="primary">
                <p>
                  It takes 46 ammo (or 2.3 seconds) to reach maximum stacks of
                  Stage 3 from zero. Whenever you trigger the final stack of the
                  previous stage, you will also trigger the first stack of the
                  next stage.
                </p>
              </Alert>
              <h5>The Great Thief & The Elusive Escapist</h5>
              <blockquote>
                <p>■ Affects self.</p>
                <p>Attack Damage ▲ 57.08% for 10 sec.</p>
                <p>Reloading Speed ▲ 25.87% for 10 sec.</p>
                <p>■ Affects all enemies.</p>
                <p>Deals 1736.31% of final ATK as Distributed Damage.</p>
              </blockquote>
              <p>
                Quency grants herself a large amount of ATK DMG and Reload Speed
                for 10s. Huge damage improvement and potentially allows{' '}
                <strong>the maintenance of Stage 2 or Stage 3 stacks</strong>{' '}
                (depending on Cube & Support Units). These effects take place
                before the nuke happens, so the nuke benefits from them.
              </p>
              <p>
                The wipe deals 1736.31% Distributed Damage to all enemies on the
                field. It is buffed by Distributed Damage▲ and Critical Rate▲
                from S1, as well as the ATK DMG▲ from this skill. It can CRIT
                but cannot core-hit. Since the damage is divided among all
                enemies, the more enemies there are, the less damage each takes.
                Problem comes when dealing with too many tanky mobs in campaign.
                If damage is divided too much, no one will be killed.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> They Are
                  Multiplicative!
                </h6>
                <p>
                  Distributed Damage▲ and ATK DMG▲ are multiplicative as the
                  former belongs in the Damage Debuff category (Damage Taken,
                  etc), while the other belongs in the Damage Buff category
                  (True DMG, Pierce DMG, etc). In other words, her Burst Skill
                  deals a perceived total of{' '}
                  <strong>~4080% of final ATK as Distributed Damage.</strong>
                </p>
              </blockquote>
              <p>
                Against single targets, we calculated that Burst Skill
                constitutes{' '}
                <strong>24% and 32% of the whole damage dealt</strong> to
                single-target enemies with and without core respectively.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="s">S</strong> | Campaign (HD)
                - <strong className="s">S</strong>
              </h5>
              <p>
                {' '}
                A generally good unit for pushing low-deficit campaign but may
                not be beginner-friendly due to OL and skill dependency. If you
                are a casual player who likes to sleep on Campaign, you will
                find QuencyEQ amazing to use. She has Hit Rate + ATK, which
                allows her to deal with enemies in close and medium range (even
                far with the correct OL) and wipe the screen effortlessly with
                her Burst Skill. Do note that{' '}
                <strong>
                  she needs to land her shots to stack or maintain S2
                </strong>
                . A good all-in-one package with an edge in Water-weak stages.
              </p>
              <p>
                {' '}
                Her performance falters slightly against high-deficit content
                due to her Burst Skill being Distributed Damage, which may or
                may not be enough to clear the screen. She is still a useful
                unit and can help with clearing Water-weak stages (with a
                similar outlook to XLud).
              </p>
              <h5>
                Bossing - <strong className="s">S</strong>
              </h5>
              <p>
                Her primary category. QuencyEQ is designed to humiliate
                Water-weak bosses since so far we only have XLud in our arsenal.
                Against bosses without adds, QuencyEQ's Burst Skill damage is
                completely focused on one boss, contributing a massive amount of
                damage. Against bosses with adds, her Burst Skill will help deal
                with critters that spawn alongside the boss at the cost of
                overall ST damage toward the boss. A few bosses that she has
                proven to shine against include Harvester in Anomaly
                Interception and very likely the currently happening Land Eater
                SR.
              </p>
              <p>
                Bosses also usually have large bodies, have more HP, and do not
                jump around a lot, making it easier for QuencyEQ to stack her S2
                and achieve her full potential. You can also previously decide
                whether to include QuencyEQ in your comps depending on the
                element of the boss, the distance, as well as the availability
                of a persistent core. She may not be a staple in every raid nor
                an everlasting unit in your best five squads, but the ability to
                deploy her when it is favorable is a great perk. If there will
                be Water-specific buffers in the future, she might as well
                re-enter the meta.
              </p>
              <h5>
                PVP - <strong className="ss">SS</strong>
              </h5>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Tier List
                  Revamp
                </h6>
                <p>
                  With the advent of Champion Arena, our PvP tier list is
                  currently pending a rework, which will be published when more
                  information about Champion Arena is announced. We will be
                  shifting our foundations to also involve Champion Arena.
                </p>
              </blockquote>
              <p>
                Her role in PvP is a{' '}
                <strong>Wiper with Follow-Up Damage</strong>, similar to Maiden,
                Rei, PrivM, BSoda, Ein, and the likes of them. Her Burst Skill,
                if Stage 1 of S2 is fully stacked, deals a total of{' '}
                <strong>
                  ~4080% Distributed Damage to all enemies (around ~816% per
                  enemy
                </strong>{' '}
                assuming everyone is alive). This only takes the ATK DMG▲ and
                Distributed Damage▲ into account, not yet the ATK▲. This is
                quite big, but it is also counterable by Taunters + Immune (the
                same way as SBS is countered) who can activate their effects
                before her Nuke.
              </p>
              <p>
                Additionally, as a Water Unit, she is also more vulnerable to
                Z.E.U.S. attacks, and coincidentally, most meta PvP units are
                Z.E.U.S., so good luck resisting those. Meanwhile, there are not
                many H.S.T.A. units she can bully.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Here we are—the most complicated section of this guide. QuencyEQ
                is very restrictive when it comes to OL, and you may have to
                spend a lot of resources perfecting her.
              </p>
              <ul>
                <li>
                  <strong>Hit Rate:</strong> QuencyEQ's kit has many Hit Rate
                  buffs, but it is just a little short of reaching our target
                  accuracy. While theoretically line-shot is achieved after 2-3×
                  Hit Rate OL, we discovered that 1-3× Hit Rate is serviceable
                  for everything. Depending on their values, you can get away
                  with one high-level Hit Rate. Make sure to turn off
                  AUTO-AIM/FIRE. Save the other slots for more impactful lines
                  such as Elemental DMG or ATK.
                </li>
                <li>
                  <strong>Max Ammo:</strong> Generally, for Resilience comps,
                  one is enough, and for Bastion comps, two is minimum. However,
                  in Reload Speed comps, when you are using characters like
                  Privaty who reduce the Max Ammo of your team, you might as
                  well play it safe by getting 2× Max Ammo to minimize chokes.
                  Not only that, it may also help you last till the next Reload
                  Speed buff (or at least cut down the number of reloads in
                  between). Consider how long the team takes to fully recharge
                  Burst Bar!
                </li>
                <li>
                  <strong>Attack:</strong> Most evident when it comes to PVP.
                  Although QuencyEQ has a lot of ATK herself already (and by the
                  time she uses Burst Skill, she will have obtained max stacks
                  of S2 unless she dies), a little more ATK from OL will help
                  push from "almost wiped" to "completely wiped". Without Damage
                  Reduction from Dolls, Quency needs more than 27% ATK from OL
                  to secure kills when paired with one buffer like Blanc, RH, or
                  Crown/Tia.
                </li>
              </ul>
              <ul>
                <li>
                  <strong>Essential:</strong> 1-2× Hit Rate, 1-2× Max Ammo
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Elemental DMG, 4× ATK, 1× Max Ammo
                </li>
                <li>
                  <strong>Passable:</strong> 1-2× CRIT DMG, 1-2× CRIT Rate, 1×
                  Hit Rate, 1× Ammo
                </li>
                <li>
                  <strong>Priority:</strong> High
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                QuencyEQ is not only OL-dependent but also skill-investment
                heavy.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 5~10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Contains practical buffs that enhance her basic attacks and
                    Burst Skill damage. To save Blue Books, Level 5 is a good
                    compromise.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Higher then regular scaling at 1.71× as opposed to 1.69×.
                    Her only source of ATK buffs and her core skill. Maximize
                    ASAP.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 8~10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Increases her damage temporarily when activated. Levels are
                    important for deciding whether her screenwipe will kill
                    anyone and/or she can reload without losing S2 stacks. As
                    mentioned in Kit Analysis, the minimum breakpoint to achieve
                    no loss of Stage 3 buff with Crown & Resilience is{' '}
                    <strong>Level 8</strong>. Upgrade further for more damage.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="quency-escape-queen" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                Depending on the comps used and OL, either{' '}
                <strong>Resilience or Bastion Level 7+</strong> will be better
                in your situation. You might as well test both to see which one
                feels more comfortable and deals more damage. Resilience
                revolves around "reload as fast as possible to avoid losing
                stacks", whereas Bastion revolves around "avoid reloading so
                that you can shoot more shots with Stage 2 & 3 stacks".
              </p>
              <ul>
                <li>
                  <strong>For Resilience specifically</strong>: you will need at
                  least Max Ammo ×1 but are suggested Max Ammo ×2 if you plan to
                  use Privaty with her. You would want to avoid reloading
                  outside Reload Speed buffs (which are usually active only
                  during Full Burst + a short duration afterward [Crown]). If
                  you have to, make sure to limit its occurrences. A good start
                  is by reloading at the end of Full Burst(s), but this does
                  come at a little bit of damage loss.
                </li>
                <li>
                  <strong>For Bastion specifically</strong>: you will need Max
                  Ammo ×2 or more. This is easier to run as you do not need to
                  worry about timings and only have to shoot as much as possible
                  to secure the highest distribution for Stage 3 shots. Pair
                  with Noir or Bunny Alice, and you might as well have "infinite
                  ammo".
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Quencyyy BEAMMMMMM!!!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="alice-wonderland-bunny"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                This team focuses on overcharging QuencyEQ with lots of Max Ammo
                and Hit Rate from Noir & Modernia (as well as Liter). With some
                investments and a good manual gameplay, QuencyEQ's shots will
                turn into a laser. Note that BunnyAlice here is a troll pick and
                serves only to complete the true infinite ammo build. QuencyEQ
                uses Bastion in this team.
              </p>
              <h5>Team #2: Wo'ah Water Woteh</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="bay-goddess-mary"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                This team utilizes SMary's Elemental DMG buff to make QEQ more
                devastating, as well as her healing to keep the team healthy.
                The last two units are FLEX and can be other units, damage
                dealers & buffers alike. We use Phantom and XLud to showcase 3
                Water DPS.
              </p>
              <h5>Team #3: Squirt Queens</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="privaty" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                This team maximizes QEQ's Burst Skill buff, Crown's Reload
                Speed, and Resilience's effect to achieve perpetual S3 stacks
                that never drop even when reloading (&gt;97.5% Reload Speed).
                However, this paradise only lasts when QEQ's Burst Skill effect
                is active for around 10s. Hence, you need to manage your Max
                Ammo well and try to only Reload when all these effects are
                active. Adding Privaty to the team will solve the timing issue
                as you can achieve Permanent Stacks every Full Burst, but she is
                not mandatory. The FLEX can be a healer or someone with high
                burst regen since this team has a slow rotation. In this team,
                QuencyEQ uses Resilience.
              </p>
              <h5>Team #4: HyperQ Miranda™</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="miranda" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                This team uses Miranda's buffs to provide Hit Rate, ATK, and
                Critical DMG. Against enemies without core, Miranda's effects
                will become more impactful due to less dilution. Seeing that QEQ
                has an innate Critical Rate buff but not Critical DMG, they
                should pair nicely together. This team relies on fishing Burst
                Skill crits.
              </p>
              <h5>Team #5: All Eyes on the Red Rapture Eyes</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="tia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Against enemies with core, QEQ and XLud can deal massive amounts
                of damage in this team. It is also very comfortable to run as it
                has shield & healing. You can also spam-tap Tia for burst gen.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Godspeed, Lightspeed</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team is derived from the OG RH + Jackal Team but with
                QuencyEQ as the main DPS. Deliver a very fast wipe with decent
                follow-up damage. It fears Biscuit as much as Scarlet fears
                Noah.
              </p>
              <h5>Zombie Queen</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="laplace-treasure"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                This is a riskier team since it requires your team must survive
                till Blanc buff Quency. Rest assure when it happens, QEQ +
                Emilia + Laplace should wipe the whole team.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          For an SMG, QuencyEQ has a versatile range thanks to
                          self-buff Hit Rate and "screenwipe". She can also hit
                          cores comfortably with proper investments.
                        </li>
                        <li>
                          One of the better Water DPS units in game, which is
                          rare, so she has very little competition.
                        </li>
                        <li>Has a lot of offensive buffs to sell.</li>
                        <li>A screen wiper with follow-up DPS in PvP.</li>
                        <li>
                          Constant damage output. Basic attacks weigh similarly
                          to Scarlet's.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Due to having a lot of offensive buffs herself, she
                          may not scale well with outside buffs, even if she can
                          synergize with many support units.
                        </li>
                        <li>
                          Requires an extreme amount of skill investments to
                          work.
                        </li>
                        <li>
                          Requires an extreme amount of Reload Speed to maintain
                          S2's effects.
                        </li>
                        <li>Demands properly managed OL.</li>
                        <li>
                          In PvP, she fears Biscuit, just like any other
                          Distributed Damage dealer.
                        </li>
                        <li>
                          Her pose is doable, but you need to train Yoga for
                          years.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRouge;

export const Head: React.FC = () => (
  <Seo
    title="Quency: Escape Queen Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Quency: Escape Queen in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
